<template>
  <div class="common">
    <ul class="title">
      <li @click="back">
        <i class="iconfont icon-back"></i>
      </li>
      <li>常见问题</li>
    </ul>
    <!-- <div class="search">
      <input type="text">
    </div> -->
    <div class="content">
      <!-- 基本操作 -->
      <div class="basic" style="width: 93%; margin: 0px auto; border-bottom: 1px solid #ccc; padding-bottom: 15px; box-sizing: border-box">
        <h1>基本操作</h1>
        <ul>
          <!-- 1. 如何生成案情分析报告？ -->
          <li @click="datalist = !datalist">
            <div class="cirle" :style="{ background: datalist ? '#4B9EFB' : '#fff' }"></div>
            <div class="cirle_left" :style="{ 'font-weight': datalist ? 'bold' : '400' }">1. 如何生成案情分析报告？</div>
          </li>
          <div class="datalist" v-if="datalist">
            <p>步骤一：打开律呗软件，进入“分析”主页面，在文本框内输入基本案情，点击“案情分析机器人按钮”，系统匹配最相似案由；</p>
            <div class="imgs">
              <img src="./img/cfvrfvr.jpg" />
            </div>
            <p>步骤二：点击匹配度最高的案由，页面显示相似判例的相关“法律依据”、“争议焦点”、“本院认为”、“诉请裁判”、“代理律师”、“诉讼时间”、“法院对比”等信息；</p>
            <div class="imgs">
              <img src="./img/anyou2.jpg" />
            </div>
            <p>步骤三：点击“更多”按键，选择“生成报告”，点击“案情分析报告”页面右上角“分享”按键，将报告链接发送给具体接收人。</p>
            <div class="imgs">
              <img src="./img/anyou.jpg" />
            </div>
          </div>
          <!-- 2. 如何创建新案件？ -->
          <li @click="twdatalist = !twdatalist">
            <div class="cirle" :style="{ background: twdatalist ? '#4B9EFB' : '#fff' }"></div>
            <div class="cirle_left" :style="{ 'font-weight': twdatalist ? 'bold' : '400' }">2. 如何创建新案件？</div>
          </li>
          <div class="datalist" v-if="twdatalist">
            <p>步骤一：打开律呗软件，进入“分析”主页面，在文本框内输入基本案情，点击“案情分析机器人按钮”，系统匹配最相似案由；</p>
            <div class="imgs">
              <img src="./img/cfvrfvr.jpg" />
            </div>
            <p>步骤二：点击匹配度最高的案由，页面显示相似判例的相关“法律依据”、“争议焦点”、“本院认为”、“诉请裁判”、“代理律师”、“诉讼时间”、“法院对比”等信息；</p>
            <div class="imgs">
              <img src="./img/anyou2.jpg" />
            </div>
            <p>步骤三：点击“更多”按键，选择“新建项目”，进入“新建项目”页面；</p>
            <div class="imgs">
              <img src="./img/step2.jpg" />
            </div>
            <p>步骤四：填写案件基本信息，点击“保存案件”，系统自动跳转到该案件详情页面，案件创建成功。</p>
            <div class="imgs">
              <img src="./img/step4.jpg" />
            </div>
          </div>
          <!-- 3.如何根据基本案情撰写法律文书？ -->
          <li @click="thdatalist = !thdatalist">
            <div class="cirle" :style="{ background: thdatalist ? '#4B9EFB' : '#fff' }"></div>
            <div class="cirle_left" :style="{ 'font-weight': thdatalist ? 'bold' : '400' }">3. 如何根据基本案情撰写法律文书？</div>
          </li>
          <div class="datalist" v-if="thdatalist">
            <p>步骤一：打开律呗软件，进入“分析”主页面，在文本框内输入基本案情，点击“案情分析机器人按钮”，系统匹配最相似案由；</p>
            <div class="imgs">
              <img src="./img/cfvrfvr.jpg" />
            </div>
            <p>步骤二：点击匹配度最高的案由，页面显示相似判例的相关“法律依据”、“争议焦点”、“本院认为”、“诉请裁判”、“代理律师”、“诉讼时间”、“法院对比”等信息；</p>
            <div class="imgs">
              <img src="./img/anyou2.jpg" />
            </div>
            <p>步骤三：点击“更多”按键，选择“写起诉书”，进入“文书撰写”页面，在页面顶部选择需要文书类型，填写相关信息；</p>
            <div class="imgs">
              <img src="./img/list.jpg" />
            </div>
            <p>步骤三：点击“文书撰写”页面“更多”按键，可以进行“预览”、“建案”、“分析”。点击“确认”按键，案件创建成功，已撰写完成的文书自动保存到该案件资料库。</p>
            <div class="imgs">
              <img src="./img/a3333333.png" />
            </div>
          </div>
          <!-- 4.如何进行法律检索？ -->
          <li @click="fodatalist = !fodatalist">
            <div class="cirle" :style="{ background: fodatalist ? '#4B9EFB' : '#fff' }"></div>
            <div class="cirle_left" :style="{ 'font-weight': fodatalist ? 'bold' : '400' }">4. 如何进行法律检索？</div>
          </li>
          <div class="datalist" v-if="fodatalist">
            <p>步骤一：打开律呗软件，进入“资讯”主页面，在页面下方文本框内输入检索关键词；</p>
            <div class="imgs">
              <img src="./img/a.jpg" />
            </div>
            <p>步骤二：点击输入框的“筛选”案件，选择“案件属性”、“案件类型”、“文书性质”、“文书性质”、“法院层级”、“审理程序”、“案由”、“审判年份”、“胜败”、“涉案金额”、“裁判金额”、“篇幅”等，点击确定；</p>
            <div class="imgs">
              <img src="./img/111111.png" />
            </div>
            <p>步骤三：点击主页面“判例”、“法律”、“知识”等分类，查看检索结果。</p>
            <div class="imgs">
              <img src="./img/a8.png" />
            </div>
          </div>

          <!-- 5.如何查看、创建日程？ -->
          <li @click="fidatalist = !fidatalist">
            <div class="cirle" :style="{ background: fidatalist ? '#4B9EFB' : '#fff' }"></div>
            <div class="cirle_left" :style="{ 'font-weight': fidatalist ? 'bold' : '400' }">5. 如何查看、创建日程？</div>
          </li>
          <div class="datalist" v-if="fidatalist">
            <p>步骤一：打开律呗软件，进入“案件”模块的“日程”主页面，将页面底端向上滑，进入“日程”主页面；</p>
            <div class="imgs">
              <img src="./img/buzhou1.png" />
            </div>
            <p>步骤二：点击“日程”主页面左上角“时间”按键，选择“四天”/“本周”/“本月”/“今年”，查看相关日程；</p>
            <div class="imgs">
              <img src="./img/buzhou2.png" />
            </div>
            <p>步骤三：回到“日程”主页面，点击“+”，进入“日程创建”页面，输入内容，点击完成，日程创建成功。</p>
            <div class="imgs">
              <img src="./img/buzhou3.png" />
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datalist: false,
      twdatalist: false,
      thdatalist: false,
      fodatalist: false,
      fidatalist: false,
    }
  },
  methods: {
    back() {
      window.goback()
    },
  },
}
</script>

<style lang="stylus" scoped>
.common
  width 100%
  height 100%
  .title
    width 100%
    height 35px
    line-height 35px
    display flex
    position fixed
    top 0px
    left 0px
    background #fff
    z-index 99
    border-bottom 1px solid #CCCCCC
    li:nth-child(1)
      margin-left 15px
    li:nth-child(2)
      font-size 14px
      color #333
      font-weight 400
      margin-left 15px
  .search
    width 93%
    border 1px solid #ccc
    border-radius 3px
    height 30px
    margin-top 40px
    margin-left 15px
    input
      width 100%
      height 95%
      border none
      outline none
      border-radius 3px
      text-indent 10px
  .content
    margin-top 45px
    h1
      font-size 14px
      color #000
      font-weight bold
    ul
      li
        display flex
        height 30px
        line-height 30px
        .cirle
          width 10px
          height 10px
          border-radius 50%
          border 1px solid #4B9EFB
          align-self center
        .cirle_left
          font-size 13px
          color #333
          font-weight 400
          margin-left 10px
      .datalist
        width 100%
        p
          font-size 13px
          color #333
          font-weight 600
        .imgs
          width 100%
          overflow hidden
          display flex
          justify-content space-between
          padding 5px
          box-sizing border-box
          margin-bottom 15px
          img
            width 200px
            max-height 380px
            border 1px solid #CCCCCC
            margin 0px auto
</style>
