var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "common" }, [
    _c("ul", { staticClass: "title" }, [
      _c("li", { on: { click: _vm.back } }, [
        _c("i", { staticClass: "iconfont icon-back" }),
      ]),
      _c("li", [_vm._v("常见问题")]),
    ]),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          staticClass: "basic",
          staticStyle: {
            width: "93%",
            margin: "0px auto",
            "border-bottom": "1px solid #ccc",
            "padding-bottom": "15px",
            "box-sizing": "border-box",
          },
        },
        [
          _c("h1", [_vm._v("基本操作")]),
          _c("ul", [
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    _vm.datalist = !_vm.datalist
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "cirle",
                  style: { background: _vm.datalist ? "#4B9EFB" : "#fff" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "cirle_left",
                    style: { "font-weight": _vm.datalist ? "bold" : "400" },
                  },
                  [_vm._v("1. 如何生成案情分析报告？")]
                ),
              ]
            ),
            _vm.datalist
              ? _c("div", { staticClass: "datalist" }, [
                  _c("p", [
                    _vm._v(
                      "步骤一：打开律呗软件，进入“分析”主页面，在文本框内输入基本案情，点击“案情分析机器人按钮”，系统匹配最相似案由；"
                    ),
                  ]),
                  _vm._m(0),
                  _c("p", [
                    _vm._v(
                      "步骤二：点击匹配度最高的案由，页面显示相似判例的相关“法律依据”、“争议焦点”、“本院认为”、“诉请裁判”、“代理律师”、“诉讼时间”、“法院对比”等信息；"
                    ),
                  ]),
                  _vm._m(1),
                  _c("p", [
                    _vm._v(
                      "步骤三：点击“更多”按键，选择“生成报告”，点击“案情分析报告”页面右上角“分享”按键，将报告链接发送给具体接收人。"
                    ),
                  ]),
                  _vm._m(2),
                ])
              : _vm._e(),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    _vm.twdatalist = !_vm.twdatalist
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "cirle",
                  style: { background: _vm.twdatalist ? "#4B9EFB" : "#fff" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "cirle_left",
                    style: { "font-weight": _vm.twdatalist ? "bold" : "400" },
                  },
                  [_vm._v("2. 如何创建新案件？")]
                ),
              ]
            ),
            _vm.twdatalist
              ? _c("div", { staticClass: "datalist" }, [
                  _c("p", [
                    _vm._v(
                      "步骤一：打开律呗软件，进入“分析”主页面，在文本框内输入基本案情，点击“案情分析机器人按钮”，系统匹配最相似案由；"
                    ),
                  ]),
                  _vm._m(3),
                  _c("p", [
                    _vm._v(
                      "步骤二：点击匹配度最高的案由，页面显示相似判例的相关“法律依据”、“争议焦点”、“本院认为”、“诉请裁判”、“代理律师”、“诉讼时间”、“法院对比”等信息；"
                    ),
                  ]),
                  _vm._m(4),
                  _c("p", [
                    _vm._v(
                      "步骤三：点击“更多”按键，选择“新建项目”，进入“新建项目”页面；"
                    ),
                  ]),
                  _vm._m(5),
                  _c("p", [
                    _vm._v(
                      "步骤四：填写案件基本信息，点击“保存案件”，系统自动跳转到该案件详情页面，案件创建成功。"
                    ),
                  ]),
                  _vm._m(6),
                ])
              : _vm._e(),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    _vm.thdatalist = !_vm.thdatalist
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "cirle",
                  style: { background: _vm.thdatalist ? "#4B9EFB" : "#fff" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "cirle_left",
                    style: { "font-weight": _vm.thdatalist ? "bold" : "400" },
                  },
                  [_vm._v("3. 如何根据基本案情撰写法律文书？")]
                ),
              ]
            ),
            _vm.thdatalist
              ? _c("div", { staticClass: "datalist" }, [
                  _c("p", [
                    _vm._v(
                      "步骤一：打开律呗软件，进入“分析”主页面，在文本框内输入基本案情，点击“案情分析机器人按钮”，系统匹配最相似案由；"
                    ),
                  ]),
                  _vm._m(7),
                  _c("p", [
                    _vm._v(
                      "步骤二：点击匹配度最高的案由，页面显示相似判例的相关“法律依据”、“争议焦点”、“本院认为”、“诉请裁判”、“代理律师”、“诉讼时间”、“法院对比”等信息；"
                    ),
                  ]),
                  _vm._m(8),
                  _c("p", [
                    _vm._v(
                      "步骤三：点击“更多”按键，选择“写起诉书”，进入“文书撰写”页面，在页面顶部选择需要文书类型，填写相关信息；"
                    ),
                  ]),
                  _vm._m(9),
                  _c("p", [
                    _vm._v(
                      "步骤三：点击“文书撰写”页面“更多”按键，可以进行“预览”、“建案”、“分析”。点击“确认”按键，案件创建成功，已撰写完成的文书自动保存到该案件资料库。"
                    ),
                  ]),
                  _vm._m(10),
                ])
              : _vm._e(),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    _vm.fodatalist = !_vm.fodatalist
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "cirle",
                  style: { background: _vm.fodatalist ? "#4B9EFB" : "#fff" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "cirle_left",
                    style: { "font-weight": _vm.fodatalist ? "bold" : "400" },
                  },
                  [_vm._v("4. 如何进行法律检索？")]
                ),
              ]
            ),
            _vm.fodatalist
              ? _c("div", { staticClass: "datalist" }, [
                  _c("p", [
                    _vm._v(
                      "步骤一：打开律呗软件，进入“资讯”主页面，在页面下方文本框内输入检索关键词；"
                    ),
                  ]),
                  _vm._m(11),
                  _c("p", [
                    _vm._v(
                      "步骤二：点击输入框的“筛选”案件，选择“案件属性”、“案件类型”、“文书性质”、“文书性质”、“法院层级”、“审理程序”、“案由”、“审判年份”、“胜败”、“涉案金额”、“裁判金额”、“篇幅”等，点击确定；"
                    ),
                  ]),
                  _vm._m(12),
                  _c("p", [
                    _vm._v(
                      "步骤三：点击主页面“判例”、“法律”、“知识”等分类，查看检索结果。"
                    ),
                  ]),
                  _vm._m(13),
                ])
              : _vm._e(),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    _vm.fidatalist = !_vm.fidatalist
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "cirle",
                  style: { background: _vm.fidatalist ? "#4B9EFB" : "#fff" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "cirle_left",
                    style: { "font-weight": _vm.fidatalist ? "bold" : "400" },
                  },
                  [_vm._v("5. 如何查看、创建日程？")]
                ),
              ]
            ),
            _vm.fidatalist
              ? _c("div", { staticClass: "datalist" }, [
                  _c("p", [
                    _vm._v(
                      "步骤一：打开律呗软件，进入“案件”模块的“日程”主页面，将页面底端向上滑，进入“日程”主页面；"
                    ),
                  ]),
                  _vm._m(14),
                  _c("p", [
                    _vm._v(
                      "步骤二：点击“日程”主页面左上角“时间”按键，选择“四天”/“本周”/“本月”/“今年”，查看相关日程；"
                    ),
                  ]),
                  _vm._m(15),
                  _c("p", [
                    _vm._v(
                      "步骤三：回到“日程”主页面，点击“+”，进入“日程创建”页面，输入内容，点击完成，日程创建成功。"
                    ),
                  ]),
                  _vm._m(16),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/cfvrfvr.jpg") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/anyou2.jpg") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/anyou.jpg") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/cfvrfvr.jpg") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/anyou2.jpg") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/step2.jpg") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/step4.jpg") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/cfvrfvr.jpg") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/anyou2.jpg") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/list.jpg") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/a3333333.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/a.jpg") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/111111.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/a8.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/buzhou1.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/buzhou2.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgs" }, [
      _c("img", { attrs: { src: require("./img/buzhou3.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }